/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409181911-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqItemsApiClass {
 
   constructor() {}

  /**
   * @summary Add an access control entry to an item
   * @param {string} id - ID of the item to add access control entry to
   */
  public addAccessControlEntry(
    body: models.AceInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/ace`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AclOutputV1>;
  }

  /**
   * @summary Archive or delete an item
   * @param {string} id - ID of the item for which to retrieve properties
   * @param {boolean} [_delete=false] - Permanently and irretrievably delete the item
   * @param {string} [archivedReason=BY_USER] - The reason for archiving the item. Can be one of OBSOLETE_SQLV1, BY_USER, DATASOURCE_CLEANUP, BY_MIGRATION, BY_PARENT_ITEM, BY_AUTO_ARCHIVE, BY_SANDBOX_MODE, or NONE
   * @param {string} [note] - An optional note for why the item was archived
   */
  public archiveItem(
    {
      id,
      _delete,
      archivedReason,
      note
    } : {
      id: string,
      _delete?: boolean,
      archivedReason?: string,
      note?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}`,

      params: omitBy({
        ['delete']: _delete,
        ['archivedReason']: archivedReason,
        ['note']: note
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Archive an item finder
   * @param {string} id - ID of the item finder to archive.
   */
  public archiveItemFinder(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/items/finders/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Change the owner of the specified item to the specified user. You need to be an administrator to be able to change the owner.
   * @param {string} itemId - ID of the item to change owner for
   * @param {string} newOwnerId - ID of the user to become the new owner
   */
  public changeOwner(
    {
      itemId,
      newOwnerId
    } : {
      itemId: string,
      newOwnerId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(newOwnerId)) {
      throw new Error("'newOwnerId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(itemId))}/owner/${encodeURIComponent(String(newOwnerId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Clear the cache for an item.
   * @param {string} id - ID of the item on which to clear cache
   * @param {boolean} [clearDependents=true] - Whether dependent caches should be cleared. Should always be true if a cache is in error, but false may be valuable when merely trying to recover disk space.
   */
  public clearCache(
    {
      id,
      clearDependents
    } : {
      id: string,
      clearDependents?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/cache`,

      params: omitBy({
        ['clearDependents']: clearDependents
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.CacheInfoV1>;
  }

  /**
   * @summary Create a context condition on a specified item. If one already exists, it will be returned
   * @param {string} id - The id of the source item
   */
  public createContextCondition(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/context`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionOutputV1>;
  }

  /**
   * @summary Create a new item finder
   */
  public createItemFinder(
    body: models.ItemFinderInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/finders`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemFinderOutputV1>;
  }

  /**
   * @summary Delete a property
   * @param {string} id - ID of the item whose property is being deleted
   * @param {string} propertyName - Name of the property to delete
   */
  public deleteProperty(
    {
      id,
      propertyName
    } : {
      id: string,
      propertyName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(propertyName)) {
      throw new Error("'propertyName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/properties/${encodeURIComponent(String(propertyName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Execute item finder searches
   */
  public executeItemFinderSearches(
    body: models.ItemFinderSearchesInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/finders/search`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemFinderSearchesOutputV1>;
  }

  /**
   * @summary Create, or find, a variant of the item that is based on the specified asset. For example, given the ID for Temperature in Area A, find its equivalent for Area B. Or, for a formula that uses Area A Temperature as an input, create a variant that that is based off Area B.
   * @param {string} id - ID of the item that is being updated
   */
  public findSwap(
    body: Array<models.SwapInputV1>,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/swap`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewV1>;
  }

  /**
   * @summary Get the current access control list for an item
   * @param {string} id - ID of the item to get access control list for
   */
  public getAccessControl(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/acl`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AclOutputV1>;
  }

  /**
   * @summary Get the current access control list for an item with details of inherited entries
   * @param {string} id - ID of the item to get access control list for
   * @param {boolean} [forceAclInheritance=false] - Show the ACL details as if access control inheritance would be enabled for the item
   */
  public getAccessControlDetails(
    {
      id,
      forceAclInheritance
    } : {
      id: string,
      forceAclInheritance?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/acl/details`,

      params: omitBy({
        ['forceAclInheritance']: forceAclInheritance
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AclOutputV1>;
  }

  /**
   * @summary Returns the context condition attached to the specified item
   * @param {string} id - The id of the source item
   */
  public getContextCondition(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/context`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionOutputV1>;
  }

  /**
   * @summary Get the formula dependencies of an Item.
   * @param {string} id - ID of the item to find dependencies of
   */
  public getFormulaDependencies(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/dependencies`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemDependencyOutputV1>;
  }

  /**
   * @summary Get a collection of items dependent on the item identified by the supplied ID.
   * @param {string} id - ID of the item to find dependents of
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getFormulaDependents(
    {
      id,
      offset,
      limit
    } : {
      id: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/dependents`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Get a collection of items interested in the item identified by the supplied ID.
   * @param {string} id - ID of the item to find all other items interested in the parameter
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getInterested(
    {
      id,
      offset,
      limit
    } : {
      id: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/interested`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Get all the properties for the item identified by the supplied ID
   * @param {string} id - ID of the item for which to retrieve properties
   */
  public getItemAndAllProperties(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemOutputV1>;
  }

  /**
   * @summary Get an item finder
   * @param {string} id - The Seeq ID for the item finder
   */
  public getItemFinder(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/finders/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemFinderOutputV1>;
  }

  /**
   * @summary Get a list of item finders
   * @param {number} [offset=0]
   * @param {number} [limit=40] - The pagination limit, the total number of item finders that will be returned in this page of results
   */
  public getItemFinders(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/finders`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemFinderOutputListV1>;
  }

  /**
   * @summary Finds all Threshold Metrics and Formulas that are directly built off the specified item.
   * @param {string} id - ID of the item to find usages
   * @param {Array<string>} [scope] - IDs that limit the results to those which are in the global scope or are scoped to the items referenced by the IDs. &lt;br&gt;The following item types can act as a scope: &lt;b&gt;Workbook&lt;/b&gt;&lt;br&gt;If the scope is set to the Empty GUID then the scoped items are excluded
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page
   * @param {number} [limit=500] - The pagination limit, the total number of collection items that will be returned in this page
   */
  public getItemUsages(
    {
      id,
      scope,
      offset,
      limit
    } : {
      id: string,
      scope?: Array<string>,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/usages`,

      params: omitBy({
        ['scope']: scope,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Get user-specific attributes of an item for the authenticated user.
   * @param {string} id - ID of the item to get attributes for
   */
  public getItemUserAttributes(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/userAttributes`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemUserAttributesOutputV1>;
  }

  /**
   * @summary Get all pinned items for an item, ordered by the most recent first
   * @param {string} [sourceId] - Id of the source item to get pinned items for. If not specified, the current user will be used.
   */
  public getPinnedItems(
    {
      sourceId
    } : {
      sourceId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/pinned`,

      params: omitBy({
        ['sourceId']: sourceId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemSearchPreviewListV1>;
  }

  /**
   * @summary Get a property
   * @param {string} id - ID of the item for which to retrieve the specified property
   * @param {string} propertyName - Name of the property to retrieve
   */
  public getProperty(
    {
      id,
      propertyName
    } : {
      id: string,
      propertyName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(propertyName)) {
      throw new Error("'propertyName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/properties/${encodeURIComponent(String(propertyName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.PropertyOutputV1>;
  }

  /**
   * @summary For the Item to be swapped in, get a ranked list of potential swap-out Items from the paths of the Items whose IDs are passed as query parameters.  The ranking is based on the number of matches found among the descendants of the swap-in Item when the listed Item is chosen as the swap-out Item, where matching is established by comparing relative paths.
   * @param {string} id - ID of the root Item to be swapped in
   * @param {Array<string>} swapOutItemIds - IDs of the items to be swapped out
   */
  public getSwapOptions(
    {
      id,
      swapOutItemIds
    } : {
      id: string,
      swapOutItemIds: Array<string>
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(swapOutItemIds)) {
      throw new Error("'swapOutItemIds' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/swapOptions`,

      params: omitBy({
        ['swapOutItemIds']: swapOutItemIds
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SwapOptionListV1>;
  }

  /**
   * @summary Lock an item and all items scoped to it. Currently only support Analyses.
   * @param {string} id - ID of the item to lock
   */
  public lockItem(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/lock`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LockedItemListOutputV1>;
  }

  /**
   * @summary Pin an item
   * @param {string} itemId - Id of the item to pin/mark as a favorite
   * @param {string} [sourceId] - Id of the source item. If not specified, the current user will be used.
   */
  public pinItem(
    {
      itemId,
      sourceId
    } : {
      itemId: string,
      sourceId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/pinned/${encodeURIComponent(String(itemId))}`,

      params: omitBy({
        ['sourceId']: sourceId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemSearchPreviewListV1>;
  }

  /**
   * @summary Remove an access control entry from an item
   * @param {string} id - ID of the item to remove access control entry from
   * @param {string} aceId - ID of the access control entry to remove
   */
  public removeAccessControlEntry(
    {
      id,
      aceId
    } : {
      id: string,
      aceId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(aceId)) {
      throw new Error("'aceId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/ace/${encodeURIComponent(String(aceId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AclOutputV1>;
  }

  /**
   * @summary Reset the Change Data Capture of an item.
   * @param {string} itemId - Id of the item to update.
   */
  public resetCdc(
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(itemId))}/resetCdc`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.PropertyOutputV1>;
  }

  /**
   * @summary Get a collection of items
   * @param {Array<string>} [filters] - A list of item filters to reduce the result set. To logical AND multiple filters, use &lt;b&gt;\&quot;filter1 &amp;&amp; filter2&lt;/b&gt;\&quot; on a single line. To logical OR multiple filters, put each onto a new line. Filters can include any of the following options:&lt;br&gt;&lt;b&gt;Empty&lt;/b&gt;: returns the first ceiling number of items&lt;br&gt;&lt;b&gt;\&quot;value\&quot;&lt;/b&gt;: name or description of the item contains the case-insensitive value&lt;br&gt;&lt;b&gt;\&quot;field&#x3D;&#x3D;value\&quot;&lt;/b&gt;: specified field exactly matches the case-sensitive value or regex&lt;br&gt;&lt;b&gt;\&quot;field~&#x3D;value\&quot;&lt;/b&gt;: specified field contains the case-insensitive value or case-sensitive regex&lt;br&gt;To search for all items in the desired scope you may also use \&quot;\\*\&quot; character as filter.&lt;br&gt;&lt;b&gt;Example:&lt;/b&gt; Find items where (name or description contains case-insensitive \&quot;bulb\&quot; AND description contains case-insensitive \&quot;area a\&quot;) OR (items hosted by datasource \&quot;Example Data\&quot; where name exactly matches the regex \&quot;.\\*[ABC]_Temperature\&quot;)&lt;br&gt;&lt;b&gt;bulb &amp;&amp; Description~&#x3D;area a&lt;/b&gt;&lt;br&gt;&lt;b&gt;Datasource ID&#x3D;&#x3D;Example Data &amp;&amp; Name&#x3D;&#x3D;/.\\*[ABC]_Temperature/&lt;/b&gt;&lt;br&gt;To allow items with the &#x27;unsearchable&#x27; property to be returned, include &lt;b&gt;\&quot;@includeUnsearchable\&quot;&lt;/b&gt; on its own line&lt;br&gt;To exclude globally scoped items when using the \&quot;scope\&quot; filter include &lt;b&gt;\&quot;@excludeGloballyScoped\&quot;&lt;/b&gt; on its own line. own line. Use a type filter with only one item type for the best performance. Some combinations of item types are not allowed and must be split into separate searches.
   * @param {Array<string>} [scope] - IDs that limit the results to those which are in the global scope or are scoped to the items referenced by the IDs. &lt;br&gt;The following item types can act as a scope: &lt;b&gt;Workbook&lt;/b&gt;&lt;br&gt;If the scope is set to the Empty GUID then the scoped items are excluded
   * @param {string} [asset] - An ID that limits the results to items which are in the tree below the Asset referenced by the ID.
   * @param {Array<string>} [types] - A list of one or more case-sensitive item types which will restrict results to only those items that exactly match, or are children of, one of the specified types. If no types are provided then items of any type are returned
   * @param {Array<string>} [includeProperties] - A list of one or more case-sensitive item properties to include in every item this search finds. Use a single entry set to &lt;b&gt;\&quot;@allProperties\&quot;&lt;/b&gt; to specify that all item properties should be returned. Specified properties not present for an item are simply omitted and do not cause errors.
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results. When using limit or offset values to query different item subsets it is important to sort the results using orderBy. Otherwise inconsistent results may be returned.
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results. When using limit or offset values to query different item subsets it is important to sort the results using orderBy. Otherwise inconsistent results may be returned.
   * @param {Array<string>} [orderBy] - An optional list of one or more fields used to sort the results.
   */
  public searchItems(
    {
      filters,
      scope,
      asset,
      types,
      includeProperties,
      offset,
      limit,
      orderBy
    } : {
      filters?: Array<string>,
      scope?: Array<string>,
      asset?: string,
      types?: Array<string>,
      includeProperties?: Array<string>,
      offset?: number,
      limit?: number,
      orderBy?: OrderByEnum[]
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items`,

      params: omitBy({
        ['filters']: filters,
        ['scope']: scope,
        ['asset']: asset,
        ['types']: types,
        ['includeProperties']: includeProperties,
        ['offset']: offset,
        ['limit']: limit,
        ['orderBy']: orderBy
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemSearchPreviewPaginatedListV1>;
  }

  /**
   * @summary Set the access control list (list of access control entries) for an item.
   * @param {string} id - ID of the item to change access control list for
   */
  public setAcl(
    body: models.AclInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/acl`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AclOutputV1>;
  }

  /**
   * @summary Turns on or off access control inheritance for the specified item
   * @param {string} id - ID of the item to change access control inheritance for
   * @param {boolean} inheritAcl - True if the item should inherit access control from its parent, false if it shouldn&#x27;t.
   */
  public setAclInheritance(
    {
      id,
      inheritAcl
    } : {
      id: string,
      inheritAcl: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(inheritAcl)) {
      throw new Error("'inheritAcl' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/inheritacl/${encodeURIComponent(String(inheritAcl))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AclOutputV1>;
  }

  /**
   * @summary Update the formula and formula parameters of an item
   * @param {string} id - ID of the item that is being updated
   */
  public setFormula(
    body: models.FormulaUpdateInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/formula`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.CalculatedItemOutputV1>;
  }

  /**
   * @summary Set user-specific attributes of an item for the authenticated user.
   * @param {string} id - ID of the item to change owner for
   */
  public setItemUserAttributes(
    body: models.ItemUserAttributesInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/userAttributes`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemUserAttributesOutputV1>;
  }

  /**
   * @summary Set properties on the item identified by the supplied ID
   * @param {string} id - ID of the item to set properties on
   */
  public setProperties(
    body: Array<models.ScalarPropertyV1>,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/properties`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemOutputV1>;
  }

  /**
   * @summary Set a property
   * @param {string} id - ID of the item to set the property on
   * @param {string} propertyName - Name of the property to set
   */
  public setProperty(
    body: models.PropertyInputV1,
    {
      id,
      propertyName
    } : {
      id: string,
      propertyName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(propertyName)) {
      throw new Error("'propertyName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/properties/${encodeURIComponent(String(propertyName))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.PropertyHrefOutputV1>;
  }

  /**
   * @summary Update the scope of an item
   * @param {string} id - ID of the item that is having its scope updated
   * @param {string} [workbookId] - ID of the Workbook to which the item is being scoped. If not provided the item will be moved to the global scope
   */
  public setScope(
    {
      id,
      workbookId
    } : {
      id: string,
      workbookId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/scope`,

      params: omitBy({
        ['workbookId']: workbookId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemOutputV1>;
  }

  /**
   * @summary Unpin an item
   * @param {string} itemId - Id of the item to unpin/unmark as a favorite
   * @param {string} [sourceId] - Id of the source item. If not specified, the current user will be used.
   */
  public unpinItem(
    {
      itemId,
      sourceId
    } : {
      itemId: string,
      sourceId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/items/pinned/${encodeURIComponent(String(itemId))}`,

      params: omitBy({
        ['sourceId']: sourceId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemSearchPreviewListV1>;
  }

  /**
   * @summary Update an item finder
   * @param {string} id - The Seeq ID for the item finder
   */
  public updateItemFinder(
    body: models.ItemFinderInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/items/finders/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemFinderOutputV1>;
  }

  /**
   * @summary Validate whether the item in question is completely lockable. Currently only support Analyses.
   * @param {string} id - ID of the item to validate
   */
  public validateItemLockability(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/items/${encodeURIComponent(String(id))}/lock/validate`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LockErrorsOutputV1>;
  }

}

export enum OrderByEnum {
    ID = 'ID' as any,
    Name = 'Name' as any,
    Description = 'Description' as any
}

export const sqItemsApi = new sqItemsApiClass();
